import styled from "styled-components";
import { HiX } from "react-icons/hi";
import { IconContext } from "react-icons";
import { device } from "../utils/devices";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: inherit;
  padding: 0 16px 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  border-radius: 12px;
  width: 85%;
  height: auto;
  @media ${device.tablet} {
    width: 50%;
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 1em;
`;

const CloseButton = styled.button`
  color: var(--brown);
  border: none;
  background-color: inherit;
`;

const ModalContentContainer = styled.div`
  width: 95%;
`;

export default function Modal({ content, onClose }) {
  const data = {
    name: content[0],
    location: content[1],
    locationURL: content[2],
    description: content[3],
    ingredients: content[4]?.split(", "),
  };

  const modalHeader = (
    <HeaderContainer>
      <h3>{data?.name}</h3>
      <IconContext.Provider value={{ size: "2em" }}>
        <CloseButton onClick={onClose}>
          <HiX />
        </CloseButton>
      </IconContext.Provider>
    </HeaderContainer>
  );

  const productInformation = (
    <ModalContentContainer>
      <a href={data?.locationURL} target="_blank" rel="noreferrer">
        {data?.location}
      </a>
      <p>{data?.description}</p>
      {data.ingredients?.length > 0 && (
        <>
          <p>Ingredients: </p>
          <ul>
            {data.ingredients.map((ingredient) => (
              <li key={ingredient}>{ingredient}</li>
            ))}
          </ul>
        </>
      )}
    </ModalContentContainer>
  );

  return (
    <>
      <Overlay onClick={onClose}></Overlay>
      <ModalContainer>
        {modalHeader}
        {productInformation}
      </ModalContainer>
    </>
  );
}
