import styled from "styled-components";
import { useState } from "react";
import { createPortal } from "react-dom";

import Modal from "../components/Modal";
import { productItems } from "../data/data";
import { device } from "../utils/devices";

const ProductContainer = styled.div`
  text-align: center;
  margin: 79px 0 2em 0;
`;

const ProductItemGroupContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
  gap: 0.5em;
  @media ${device.tablet} {
    justify-content: center;
    margin: 0 3em;
    gap: 2em;
  }
  @media ${device.laptop} {
    margin: 0 10em;
    gap: 2em;
  }
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: auto;
  border-radius: 5px;
  max-width: 100%;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const ProductCardLink = styled.a`
  text-decoration: none;
  color: inherit;
  max-width: 100%;
`;

const ProductCardImg = styled.img`
  border-radius: 5px 5px 0 0;
  max-height: 322px;
  object-fit: cover;
`;

const ProductCardText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1em;
`;

const ProductCardTextHeader = styled.h3`
  margin-bottom: 0;
`;

const ProductCardSmall = styled(ProductCard)`
  max-width: 175px;
  height: 443px;
  @media ${device.tablet} {
    max-width: 350px;
  }
  @media ${device.laptop} {
    max-width: 258px;
  }
`;

const Products = () => {
  const [modalData, setModalData] = useState([]);

  const productGroup = (
    <>
      {productItems
        .map((product) =>
          product.link ? (
            <ProductCardLink
              href={product.link}
              target="_blank"
              rel="noreferrer"
            >
              <ProductCardSmall key={product.id}>
                <ProductCardImg src={product.img} alt={product.name} />
                <ProductCardText>
                  <ProductCardTextHeader>
                    <b>{product.name}</b>
                  </ProductCardTextHeader>
                  <p>
                    {product.tagline
                      ? product.tagline
                      : product.description?.substring(0, 25) + "..."}
                  </p>
                </ProductCardText>
              </ProductCardSmall>
            </ProductCardLink>
          ) : (
            <ProductCardSmall
              key={product.id}
              onClick={() =>
                setModalData([
                  product.name,
                  product.locationName,
                  product.locationURL,
                  product.description,
                  product.ingredients,
                ])
              }
            >
              <ProductCardImg src={product.img} alt={product.name} />
              <ProductCardText>
                <ProductCardTextHeader>
                  <b>{product.name}</b>
                </ProductCardTextHeader>
                <p>
                  {product.tagline
                    ? product.tagline
                    : product.description?.substring(0, 25) + "..."}
                </p>
              </ProductCardText>
            </ProductCardSmall>
          )
        )
        .reverse()}

      {modalData.length > 1 &&
        createPortal(
          <Modal content={modalData} onClose={() => setModalData([])} />,
          document.body
        )}
    </>
  );

  return (
    <ProductContainer>
      <div>
        <h1>Our Products</h1>
        <h2>Available in Stores</h2>
      </div>
      <ProductItemGroupContainer>{productGroup}</ProductItemGroupContainer>
    </ProductContainer>
  );
};

export default Products;
