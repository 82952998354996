import styled from "styled-components";

import { device } from "../utils/devices";
import { aboutItems } from "../data/data";

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 79px 2em 1em 2em;
  gap: 1em;
`;

const AboutInfoGroup = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    margin: 1em;
  }
`;

const AboutItem = styled.div`
  @media ${device.tablet} {
    flex: 47%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const AboutItemImg = styled.img`
  max-width: 100%;
  border-radius: 10px;
`;

const AboutItemTitle = styled.h3`
  text-align: center;
`;

const About = function () {
  const aboutInfoGroup = (
    <>
      {aboutItems.map((item) => (
        <AboutInfoGroup>
          <AboutItem style={{ order: item.id }}>
            <AboutItemImg src={item.img} alt="" />
          </AboutItem>
          <AboutItem
            style={
              item.id % 2 === 0
                ? { order: item.id + 1 }
                : { order: item.id - 1 }
            }
          >
            <AboutItemTitle>{item.title}</AboutItemTitle>
            <p>{item.details1}</p>
            <p>
              {item?.details2}
              <a href={item?.linkURL} target="_blank" rel="noreferrer">
                {item?.linkText}
              </a>
            </p>
          </AboutItem>
        </AboutInfoGroup>
      ))}
    </>
  );

  return (
    <AboutContainer>
      <h1>About Us</h1>
      {aboutInfoGroup}
      <h2>
        We have many exciting new expansions to our services coming soon, so
        stay tuned!
      </h2>
    </AboutContainer>
  );
};
export default About;
