import styled from "styled-components";
import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const AppContainer = styled.div`
  height: 100svh;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

function Root() {
  return (
    <AppContainer>
      <NavBar />
      <Outlet />
      <Footer />
    </AppContainer>
  );
}

export default Root;
