import aboutImg0 from "../assets/images/about/selfclose.webp";
import aboutImg1 from "../assets/images/about/selfwith2kids.webp";

import recipeImg1 from "../assets/images/shop/recipeImg1.jpg";
import recipeImg2 from "../assets/images/shop/recipeImg2.jpg";
import recipeImg3 from "../assets/images/shop/recipeImg3.jpg";
import recipeImg4 from "../assets/images/shop/recipeImg4.jpg";
import recipeImg5 from "../assets/images/shop/recipeImg6.jpg";
import recipeImg6 from "../assets/images/home/DragonFruitSmoothieBowl.jpg";
import recipeImg7 from "../assets/images/shop/BreakfastBrochette.jpg";
import recipeImg8 from "../assets/images/shop/ChickpeaVeganTuna.jpg";
import recipeImg9 from "../assets/images/shop/SpinachArticokeStuffedMiniPeppers.jpg";
import recipeImg10 from "../assets/images/shop/SweetPotatoOreoCheesecake.jpg";
import recipeImg11 from "../assets/images/shop/SweetAndSourChiliPepperMarinatedGrilledTofu.jpg";
import recipeImg12 from "../assets/images/shop/PeanutButterCookiesAndCreamCheesecake.jpg";

import productImg0 from "../assets/images/products/cookiesandcreamcheesecake.webp";
import productImg1 from "../assets/images/products/vitamininfusedkeylimecheesecake.webp";
import productImg2 from "../assets/images/products/vitamininfusedlemoncheesecake.webp";
import productImg3 from "../assets/images/products/cashewcheese.webp";
import productImg4 from "../assets/images/products/tvpkit.webp";
import productImg5 from "../assets/images/products/seitanmix.webp";
import productImg6 from "../assets/images/products/spiceyseasonedextravirginoliveoil.webp";
import productImg7 from "../assets/images/products/image000002.jpg";
import productImg8 from "../assets/images/products/VegansBestBook_cropped.jpg";

export const navItems = [
  {
    id: 0,
    text: "Home",
    path: "/",
  },
  {
    id: 1,
    text: "About Us",
    path: "about",
  },
  {
    id: 2,
    text: "Products",
    path: "products",
  },
  {
    id: 3,
    text: "Shop",
    link: "https://store.vegans-best-by-b.com/",
  },
  {
    id: 4,
    text: "Contact",
    path: "contact",
  },
];

export const aboutItems = [
  {
    id: 0,
    img: aboutImg0,
    title: "Meet Barookah",
    details1:
      "I began cooking at the age of 8-years-old and quickly developed a passion for it. By the age of 11, I was cooking for my entire household. My favorite part of the week was when I used my cooking to draw family members away form their busy lives to gather around the table and have meaningful conversations filled with love and support. Now, I do the same with my own family. Cooking for my husband and children has become a meditative process, ending with us all gathering around the table and sharing our day with one another.",
    details2:
      "I love creating community by sharing delicious food, but many families today aren’t afforded that opportunity. The prevalence of cheap fast food and lack of approachable methods prevent many from learning not only how to cook, but how to love cooking. My passion lies in bringing families back to the table with delicious, fun, vegan recipes that promote physical and mental health and wellness within our families. Remember, your health is your wealth!",
  },
  {
    id: 1,
    img: aboutImg1,
    title: "Vegan's Best by B",
    details1:
      "Being vegan can be challenging. Good vegan food sometimes seems limited to a tofu-sprinkled salad or a bean burger patty acting as a sad meat substitute, and the vegan community can be less approachable than one would hope. But for me, vegan food has always been my bread and (vegan) butter. I was raised in a community where being vegan was a way of life, not a two-week diet to feel superior about. Our vegan food was made simply and deliciously, surrounded by a grounded and loving community.",
    details2:
      "Vegan's Best by B is a family of those who care about their health, wellness, and each other. We offer mouth-watering recipes that can be used either to complement a larger meal—such as our vitamin-infused cheesecake—or to replace meat-based meals entirely. We make sure our recipes are accessible for beginners while still being fun for more advanced cooks. All recipes provided come with a downloadable version for easy access as well as an online instructional video. We also offer meal prep and some ready-made products, sold at ",
    linkURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    linkText: "Bonne Sante Health Foods.",
  },
];

export const recipeItems = [
  {
    id: 0,
    img: recipeImg1,
    name: "Avocado Chocolate Mousse",
    timeEst: "30 mins",
    description: "Avocado Chocolate Mousse description",
  },
  {
    id: 1,
    img: recipeImg2,
    name: "Vegan Tacos",
    timeEst: "30 mins",
    description: "Vegan Tacos description",
  },
  {
    id: 2,
    img: recipeImg3,
    name: "Coconut Alfredo Curry Pasta",
    timeEst: "30 mins",
    description: "Coconut Alfredo Curry Pasta description",
  },
  {
    id: 3,
    img: recipeImg4,
    name: "Sweet Potato Oreo Cheesecake Rolls",
    timeEst: "60 mins",
    description: "Sweet Potato Oreo Cheesecake Rolls description",
  },
  {
    id: 4,
    img: recipeImg5,
    name: "Mac and Cheese with Candied Yams",
    timeEst: "60 mins",
    description: "Mac and Cheese with Candied Yams description",
  },
  {
    id: 5,
    img: recipeImg6,
    name: "Dragon Fruit Smoothie Bowl",
    timeEst: "30 mins",
    description: "Dragon Fruit Smoothie Bowl description",
  },
  {
    id: 6,
    img: recipeImg7,
    name: "Breakfast Brochette",
    timeEst: "30 mins",
    description:
      "Made with guacamole, vegan omelette, grilled onions, cherry tomatoes and drizzled with a homemade thousand island dressing",
  },
  {
    id: 7,
    img: recipeImg8,
    name: "Chickpea Vegan Tuna",
    timeEst: "30 mins",
    description:
      "This is a chickpea vegan tuna salad on a sheet of seaweed with cherry tomatoes on top and some seaweed for decoration",
  },
  {
    id: 8,
    img: recipeImg9,
    name: "Spinach Artichoke Stuffed Mini Peppers",
    timeEst: "30 mins",
    description:
      "",
  },
  {
    id: 9,
    img: recipeImg10,
    name: "Sweet Potato Oreo Cheesecake",
    timeEst: "30 mins",
    description:
      "",
  },
  {
    id: 10,
    img: recipeImg11,
    name: "Sweet and Sour Chili Pepper Marinated Grilled Tofu",
    timeEst: "30 mins",
    description:
      "A perfect side dish or over some rice as a main course.",
  },
  {
    id: 11,
    img: recipeImg12,
    name: "Peanut Butter Cookies And Cream Cheesecake",
    timeEst: "30 mins",
    description:
      "",
  },
];

export const productItems = [
  {
    id: 0,
    img: productImg1,
    name: "Vitamin Infused Key Lime Cheesecake",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
      "Key Lime Cheesecake infused with vitamins. A healthy way to treat yourself and your body!",
    ingredients:
      "Tofutti cream cheese, Lime juice, Sea salt, Chlorophyll vitamin C, Agave, Arrowroot",
  },
  {
    id: 1,
    img: productImg0,
    name: "Cookies and Cream Cheesecake",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description: "Made with sandwich cookie crumbles, our Cookies and Cream Cheesecake makes a healthy and satisfying dessert treat",
    ingredients: "Cookies, Tofutti, Agave, Arrowroot, Salt, Vanilla, Cinnamon",
  },
  {
    id: 2,
    img: productImg2,
    name: "Vitamin Infused Lemon Cheesecake",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
      "Lemon Cheesecake infused with vitamins. A healthy way to treat yourself and your body!",
    ingredients:
      "Tofutti cream cheese, Agave, Sea salt, Carrot juice, Lemon juice, Arrowroot, Graham crackers",
  },
  {
    id: 3,
    img: productImg3,
    name: "Cashew Cheese",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
      "This is a soy free and dairy free cheese that you can use in a verity of ways: either as a dip for vegan nachos or a topping for vegan tacos, mac and cheese and so much more. Check our social media for more ideas",
    ingredients:
      "Cashews, Coco Milk, Peppers, Nutritional Yeast, Sea Salt, Spices",
  },
  {
    id: 4,
    img: productImg5,
    name: "Seitan Mix",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
    "Just add water, olive oil, and liquid smoke before baking our Seitan mix to create a delicious addition to your next vegan gyros, vegan Italian beefs, veggie stir fry, or vegan barbeque. Full instructions on package."
  },
  {
    id: 5,
    img: productImg6,
    name: "Spicey Seasoned Extra Virgin Olive Oil",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
      "This spicey seasoned extra virgin olive oil can be used for cooking, dipping bread into or pour it over hummas for delicious an nutritious treat",
  },
  {
    id: 6,
    img: productImg4,
    name: "T.V.P. Kit",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
      "Created during soybean oil extraction, our Texturized Vegetable Protein (T.V.P.) is the perfect meat substitute for your tacos, chili, spaghetti, mostacholi, or stuffing.",
  },
  {
    id: 7,
    img: productImg7,
    name: "Nutritional Yeast Seasoning",
    locationName: "Bonne Sante Health Foods",
    locationURL: "https://goo.gl/maps/ZAuxe4iRr9A5hcv49",
    description:
      "Our herbal nutritional yeast is a lightly salted seasoning perfect for any vegan snacks or meals.",
  },
  {
    id: 8,
    img: productImg8,
    name: "Vegans Best Cookbook",
    link: "https://www.amazon.com/Vegans-Best-Your-health-wealth/dp/B0CDNGK8J8",
    tagline:
      "Our newly released cookbook!",
  },
];
