import styled from "styled-components";
import { useRouteError } from "react-router-dom";

const ErrorContainer = styled.div`
  // flex: "1 0 auto";
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Error = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <ErrorContainer>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </ErrorContainer>
  );
};

export default Error;
