import { useState } from "react";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { HiMenu, HiX } from "react-icons/hi";
import { navItems } from "../../data/data";
import logo from "../../assets/images/logo/VBLogo_1x_edited.png";
import "./NavBar.css";

const NavBar = function () {
  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const handleButtonClick = () => {
    setNavMenuOpen((prev) => !prev);
  };

  const renderLogo = (
    <div className="logo-container">
      <NavLink to="/" end style={{ display: "flex", flexDirection: "column" }}>
        <img src={logo} alt="" width="25%" />
        <span className="logo">Vegan's Best by B</span>
      </NavLink>
    </div>
  );

  const navMenu = (
    <>
      {navItems.map((item) => (
        <div key={item.id} onClick={() => setNavMenuOpen(false)}>
          {item.link ? <a href={item.link}>{item.text}</a> :
          <NavLink
            to={item?.path}
            className={`${({ isActive }) => (isActive ? "active" : undefined)}`}
          >
            {item.text}
          </NavLink>
          }
        </div>
      ))}
    </>
  );

  const overlayMenu = (
    <>
      {navMenuOpen && (
        <div
          id="myNav"
          className={`overlay${navMenuOpen ? " show-links" : ""}`}
        >
          <div className="overlay-content">{navMenu}</div>
        </div>
      )}
    </>
  );

  return (
    <>
      {overlayMenu}
      <nav className="nav-bar">
        <div className="nav-logo-and-button">
          {renderLogo}
          <IconContext.Provider value={{ size: "3em" }}>
            <button onClick={handleButtonClick} className="menu-btn">
              {!navMenuOpen ? <HiMenu /> : <HiX />}
            </button>
          </IconContext.Provider>
          {/* <ul className={`nav-links${navMenuOpen ? " show-links" : ""}`}>
        {navMenu}
      </ul> */}
          <div className="nav-links">{navMenu}</div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
