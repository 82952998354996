import styled from "styled-components";
import { useState, useRef } from "react";
import { IconContext } from "react-icons";
import { HiX } from "react-icons/hi";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2em;
  margin-left: 2em;
  margin-bottom: 1em;
  border-radius: 5px;
`;

const MessageContainer = styled.div`
  color: var(--brown);
  margin-top: 4em;
  text-align: center;
`;

const ThankYouMessage = styled(MessageContainer)`
  background-color: lightgreen;
`;

const ErrorMessage = styled(MessageContainer)`
  background-color: red;
`;

const MessageCloseButton = styled.button`
  position: absolute;
  top: 75px;
  right: 0;
  background-color: transparent;
  border: none;
  color: var(--brown);
`;

const MessageText = styled.h3`
  margin: 2em 1em;
`;

const SubmitButton = styled.button`
  background-color: var(--green);
  color: white;
  padding: 1em;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
`;

const EmailErrorSpan = styled.span`
  color: red;
  font-weight: bold;
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
`;

const Contact = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    emailError: "",
  });

  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const SCRIPT_ID =
    "AKfycbxedlaTCuT30cLWUgSWYUdSvo02jGhTyqE4_Qmkr5n81OBt6lFPzr4BeIUmAikSpVesKQ";
  const SCRIPT_URL = `https://script.google.com/macros/s/${SCRIPT_ID}/exec`;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (nameRef === "" || emailRef === "") {
      setErrorText("Error: Please fill in both fields.");
    } else {
      const url =
        SCRIPT_URL +
        "?name=" +
        nameRef.current.value +
        "&email_address=" +
        emailRef.current.value +
        "&message=" +
        messageRef.current.value;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      }).then((res) => {
        setIsSuccess(true);
        setFormData({
          name: "",
          email: "",
          message: "",
          emailError: "",
        });
      });
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "email" && !validEmailRegex.test(value)) {
      setFormData({ emailError: "Email is not valid" });
    } else {
      setFormData({ emailError: null });
    }
  };

  const renderCloseBtn = (
    <IconContext.Provider value={{ size: "2em" }}>
      <MessageCloseButton onClick={() => setIsSuccess(false)}>
        <HiX />
      </MessageCloseButton>
    </IconContext.Provider>
  );

  const renderMessage = () => {
    if (isSuccess) {
      return (
        <ThankYouMessage
          style={isSuccess ? { display: "block" } : { display: "hidden" }}
        >
          {renderCloseBtn}
          <MessageText>
            {`Thank you ${nameRef.current.value}, for your message. We will respond by email at our earliest convenience`}
          </MessageText>
        </ThankYouMessage>
      );
    } else if (errorText) {
      return (
        <ErrorMessage
          style={isSuccess ? { display: "block" } : { display: "hidden" }}
        >
          {renderCloseBtn}
          <MessageText>
            {`There was an error sending the message: ${errorText}`}
          </MessageText>
        </ErrorMessage>
      );
    }
  };

  const renderForm = (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name</label>
        <FormInput
          required
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          ref={nameRef}
        />
      </div>
      <div style={{ marginBottom: "1em" }}>
        <label htmlFor="email">Email</label>
        <FormInput
          required
          type="text"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          ref={emailRef}
          style={{ marginBottom: 0 }}
        />
        <EmailErrorSpan>{formData.emailError}</EmailErrorSpan>
      </div>
      <div>
        <label htmlFor="message">Message</label>
        <FormTextArea
          required
          name="message"
          cols="30"
          rows="10"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          ref={messageRef}
        ></FormTextArea>
      </div>
      <SubmitButton
        type="submit"
        style={
          formData.emailError
            ? { opacity: "0.6", cursor: "not-allowed" }
            : { opacity: "1", cursor: "allowed" }
        }
        disabled={formData.emailError}
      >
        Send Message
      </SubmitButton>
    </form>
  );

  return (
    <>
      {renderMessage()}
      <ContactContainer
        style={isSuccess ? { marginTop: 0 } : { marginTop: "79px" }}
      >
        <h1>Contact Us!</h1>
        <p>Send us a Message!</p>
        {renderForm}
      </ContactContainer>
    </>
  );
};

export default Contact;
