import styled from "styled-components";
import backgroundImage1 from "../assets/images/home/DragonFruitSmoothieBowl.jpg";

const Container = styled.div`
  flex: 1 0 auto;
  margin-top: 79px;
  background-image: url(${backgroundImage1});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #464646;
  // opacity: 0.3;
`;

const Home = () => {
  return (
    <>
      <Container />
    </>
  );
};

export default Home;
