import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiFacebook, FiInstagram, FiPhone, FiYoutube } from "react-icons/fi";
import { TbBrandTiktok } from "react-icons/tb";
import { device } from "../utils/devices";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
  align-items: center;
  background-color: inherit;
  color: var(--green);
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const SocialDiv = styled.div`
  display: flex;
  gap: 0.5em;
`;

const SocialSpan = styled.span`
  font-size: 20px;
`;

const SocialLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const MyWebsiteLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: 12px;
`;

const socialLinks = (
  <IconContext.Provider value={{ size: "1.5em" }}>
    <SocialLink
      href="https://www.instagram.com/vegans_bestbyb"
      target="_blank"
      rel="noreferrer"
    >
      <FiInstagram />
    </SocialLink>
    <SocialLink
      href="https://youtube.com/@vegansbestbyb2422"
      target="_blank"
      rel="noreferrer"
    >
      <FiYoutube />
    </SocialLink>
    <SocialLink
      href="https://www.facebook.com/profile.php?id=100087880680190&mibextid=ZbWKwL"
      target="_blank"
      rel="noreferrer"
    >
      <FiFacebook />
    </SocialLink>
    <SocialLink
      href="https://www.tiktok.com/@vegans_bestbyb?_t=8b8UNCFxQxW&_r=1"
      target="_blank"
      rel="noreferrer"
    >
      <TbBrandTiktok />
    </SocialLink>
    <SocialLink
      href="tel:773-516-0619"
      target="_blank"
      rel="noreferrer"
    >
      <FiPhone />
    </SocialLink>
  </IconContext.Provider>
);

const Footer = function () {
  return (
    <FooterContainer>
      <SocialDiv>
        <SocialSpan>Say hi to us! </SocialSpan>
        {socialLinks}
      </SocialDiv>
      <MyWebsiteLink
        href="http://seancraig.me"
        target="_blank"
        rel="noreferrer"
      >
        Designed and Developed by Sean Craig
      </MyWebsiteLink>
    </FooterContainer>
  );
};

export default Footer;
