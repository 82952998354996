import styled from "styled-components";
import { useState } from "react";
import { createPortal } from "react-dom";
import { recipeItems } from "../data/data";
import { device } from "../utils/devices";
import Modal from "../components/Modal";

const ShopContainer = styled.div`
  text-align: center;
  margin: 79px 0.5em 2em 0.5em;
`;

const ShopContainerItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0.5em;
  @media ${device.tablet} {
    gap: 2em;
  }
`;

const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: auto;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const RecipeCardImg = styled.img`
  width: auto;
  height: 150px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
`;

const RecipeCardText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RecipeCardSmall = styled(RecipeCard)`
  max-width: 45%;
  @media ${device.tablet} {
    max-width: 15%;
  }
`;

const RecipeCardLarge = styled(RecipeCard)`
  max-width: 85%;
  @media ${device.tablet} {
    max-width: 30%;
  }
`;

const Shop = () => {
  const [modalData, setModalData] = useState([]);

  const recipeGroup = (
    <>
      {recipeItems
        .map((recipe) =>
          recipeItems.length > 5 ? (
            <RecipeCardSmall
              key={recipe.id}
              onClick={() => setModalData([recipe.name, recipe.description])}
            >
              <RecipeCardImg src={recipe.img} alt={recipe.name} />
              <RecipeCardText>
                <h4>
                  <b>{recipe.name}</b>
                </h4>
                <p>{recipe.timeEst}</p>
              </RecipeCardText>
            </RecipeCardSmall>
          ) : (
            <RecipeCardLarge
              key={recipe.id}
              onClick={() => setModalData([recipe.name, recipe.description])}
            >
              <RecipeCardImg src={recipe.img} alt={recipe.name} />
              <RecipeCardText>
                <h4>
                  <b>{recipe.name}</b>
                </h4>
                <p>{recipe.timeEst}</p>
              </RecipeCardText>
            </RecipeCardLarge>
          )
        )
        .reverse()}

{modalData.length > 1 &&
        createPortal(
          <Modal content={modalData} onClose={() => setModalData([])} />,
          document.body
        )}
    </>
  );

  return (
    <ShopContainer>
      <div>
        <h1>Shop is coming soon</h1>
        <h3>
          Here are a few of the recipes that will be available when the shop
          launches
        </h3>
      </div>
      <ShopContainerItems
        style={recipeItems.length > 5 ? { gap: "0.5em" } : { gap: "2em" }}
      >
        {recipeGroup}
      </ShopContainerItems>
    </ShopContainer>
  );
};

export default Shop;
